body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.image-gallery-slide-wrapper{background-color: black;}
.ant-input{height: 30px; font-size: 14px;}

.react-flow__node-default,
.react-flow__node-input,
.react-flow__node-output,
.react-flow__node-group{padding: 0 !important; border: none !important;}

.react-flow__panel.react-flow__attribution.bottom.right{display: none !important;}